import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Reports from './Reports'
import TransactionList from './TransactionList'
import UploadFile from './UploadFile'

// Windows where the actual navigation happens
const Main = () => (
    <main>
        <Switch>
            <Route exact path='/' component={UploadFile} />
            <Route path='/transactions' component={TransactionList} />
            <Route path='/reports' component={Reports} />
        </Switch>
    </main>
)

export default Main