import React from 'react'
import FilePicker from '../components/FilePicker';
import { useHistory } from "react-router-dom";
import logo from '../logo.svg';
import { appStore } from '../appStore';

const UploadFile = () => {

    const history = useHistory();

    const transactions = appStore((state) => state.transactions);
    const isFileUploaded = appStore((state) => state.isFileUploaded);

    const setFileUpload = appStore((state) => state.seIisFileUploaded);
    const setTransactions = appStore((state) => state.setTransactions);

    return (
        <div>
            <img src={logo} className="App-logo" alt="logo" />

            <FilePicker onFileChanged={data => {
                console.log(data)

                setTransactions(data)
                setFileUpload(true)

                // Navigate to transactions list
                history.push("/transactions");
            }}
                onError={e => { }}
                onFileRemoved={data => { }}
                hasHeaders={true}
            />
        </div>
    );
}

export default UploadFile;
