import React from 'react'
import { CSVReader } from 'react-papaparse'
import PropTypes from "prop-types";

const FilePicker = ({ onFileChanged, onError, onFileRemoved, hasHeaders }) => {

    function createTransaction(x) {
        return {
            account: x.data[0],
            date: x.data[1],
            description: x.data[2],
            originalDescription: x.data[3],
            amount: x.data[4],
            l1Tag: x.data[5],
            l2Tag: x.data[6],
            l3Tag: x.data[7]
        }
    }

    return (
        <CSVReader
            onDrop={data => {
                if (hasHeaders) {
                    // Ignore the header
                    let [, ...rest] = data
                    onFileChanged(rest.map(createTransaction))
                }
                else {
                    onFileChanged(data.map(createTransaction))
                }
            }}
            onError={onError}
            addRemoveButton
            removeButtonColor='#659cef'
            onRemoveFile={onFileRemoved}
        >
            <span>Drop CSV file here or click to upload.</span>
        </CSVReader>
    );
}

FilePicker.propTypes = {
    onFileChanged: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onFileRemoved: PropTypes.func.isRequired,
    hasHeaders: PropTypes.bool.isRequired
};

export default FilePicker;