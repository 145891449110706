import React from 'react'
import PropTypes from "prop-types";

const Transaction = ({ transaction }) => {

    return (
        <tr>
            <td>{transaction.account}</td>
            <td>{transaction.date}</td>
            <td>{transaction.description}</td>
            <td>{transaction.originalDescription}</td>
            <td>{transaction.amount}</td>
            <td>{transaction.l1Tag}</td>
            <td>{transaction.l2Tag}</td>
            <td>{transaction.l3Tag}</td>
        </tr>
    );
}

Transaction.propTypes = {
    transaction: PropTypes.object.isRequired
};

export default Transaction;