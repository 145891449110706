import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { appStore } from '../appStore';

const Reports = ({ title = "Default" }) => {

    var savedTransactions = appStore((state) => state.transactions);

    return (
        <div>Reports page:</div>
    );
}

Reports.propTypes = {
    title: PropTypes.string.isRequired
};

export default Reports;
