import React from 'react';
import { appStore } from '../appStore';
import Transaction from '../components/Transaction';


function TransactionList() {

    var isFileUploaded = appStore((state) => state.isFileUploaded);
    var transactions = appStore((state) => state.transactions);

    return (
        <div>
            <div>Transaction list page: File upload? {isFileUploaded ? 'true' : 'false'}</div>
            <div>
                <table>
                    <tr>
                        <th>Account</th>
                        <th>Date</th>
                        <th>CurrentDescription</th>
                        <th>OriginalDescription</th>
                        <th>Amount</th>
                        <th>L1Tag</th>
                        <th>L2Tag</th>
                        <th>L3Tag</th>
                    </tr>
                    {transactions.map((t, index) => <Transaction key={index} transaction={t} />)}
                </table>
            </div>
        </div>
    );
}

export default TransactionList;
